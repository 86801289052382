export const baseEventPayload = ({ bucketData }) => {
    const { title, uniqueId, position } = bucketData;

    return {
        'Bucket Id': uniqueId,
        'Bucket Name': title,
        'Bucket Location': position,
    };
};

export const scEventPayload = ({ bucketData, scId, scName }) => ({
    ...baseEventPayload({ bucketData }),
    'SC Id': scId,
    'SC Name': scName,
});
