import { VideoAspectRatio } from '../../types/video';

export const getScreenAspectRatio = (): VideoAspectRatio => {
    if (typeof window === 'undefined') {
        return 'WIDESCREEN';
    }

    const width = window?.screen.width;
    const height = window?.screen.height;
    const ratio = width / height;

    const desktopAspectRatio = 16 / 9;
    const mobileAspectRatio = 4 / 5;

    // Assuming that if the ratio is closer to 16:9, it's a desktop, otherwise it's mobile
    if (Math.abs(ratio - desktopAspectRatio) < Math.abs(ratio - mobileAspectRatio)) {
        return 'WIDESCREEN';
    } else {
        return 'PORTRAIT';
    }
};
