import pathfinder from '@fiverr-private/pathfinder';
import { translate } from '@fiverr-private/i18n-react';
import { PATHFINDER } from '../../../../../lib/constants/pathfinder';
import { PATHS } from '../../../../../lib/constants/verticalsData';

export const getNestedSubCategoryPath = ({ categoryId, subCategoryId, nestedSubCategoryId }, source) => {
    if (!(categoryId && subCategoryId && nestedSubCategoryId)) {
        return PATHS.ROOT;
    }

    if (
        isNaN(parseInt(categoryId, 10)) ||
        isNaN(parseInt(subCategoryId, 10)) ||
        isNaN(parseInt(nestedSubCategoryId, 10))
    ) {
        return PATHS.ROOT;
    }

    const categorySlug = translate(`categories.category_${categoryId}.cached_slug`) || '';
    const subCategorySlug = translate(`sub_categories.sub_category_${subCategoryId}.cached_slug`) || '';
    const nestedSubCategorySlug =
        translate(`sub_categories.sub_category_${subCategoryId}.nested_sub_cat_${nestedSubCategoryId}.cached_slug`) ||
        '';

    const params = {
        category_id: categorySlug,
        id: subCategorySlug,
        nested_sub_category_id: nestedSubCategorySlug,
    };

    const options = { query: { source } };

    return categorySlug && subCategorySlug && nestedSubCategorySlug
        ? pathfinder(PATHFINDER.NESTED_SUB_CATEGORY, params, options)
        : '';
};
