import React, { useEffect } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import VerticalPage from '../../components/VerticalPage';
import tracker from '../../utils/tracker';
import { trackVerticalPageLoad } from '../../utils/gtmTracking/gtmTracking';
import BigQuery from '../../utils/bigQuery';

interface AppInitialProps {
    verticalSlug: VerticalSlugType;
    vertical: IVertical;
    isBusiness: boolean;
    dataLayerObject: DataLayerObjectType;
    rollouts: any;
    isMobile: boolean;
    pageCtxId: string;
    userGuid: string;
    organizationId: string;
    currentBannerIndex: number;
}

const App = ({
    vertical,
    verticalSlug,
    isBusiness,
    dataLayerObject,
    rollouts,
    isMobile,
    pageCtxId,
    userGuid,
    organizationId,
    currentBannerIndex,
}: AppInitialProps) => {
    useEffect(() => {
        const { userId } = getContext();
        const isLoggedIn = !!userId;

        trackVerticalPageLoad(dataLayerObject);
        tracker.initialize(vertical);
        tracker.view(verticalSlug, isLoggedIn);

        BigQuery.initialiseContext({
            isMobile,
            userId,
            pageCtxId,
            userGuid,
            organizationId,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (vertical) {
        return (
            <VerticalPage
                verticalData={vertical}
                isBusiness={isBusiness}
                rollouts={rollouts}
                currentBannerIndex={currentBannerIndex}
            />
        );
    } else {
        return 'Ops Page'; // TODO
    }
};

export default App;
