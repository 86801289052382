import pathfinder from '@fiverr-private/pathfinder';
import {
    AGENCIES_DIGITAL_MARKETING_BANNER_TAG_KEYS,
    AGENCIES_GRAPHICS_DESIGN_BANNER_TAG_KEYS,
    AGENCIES_PROGRAMMING_BANNER_TAG_KEYS,
    CATEGORIES_ID_MAPPER,
    TYPES,
} from '../../../lib/constants/verticalsData';
import {
    DIGITAL_MARKETING_BACKGROUND_IMAGE_URL,
    GRAPHICS_DESIGN_BACKGROUND_IMAGE_URL,
    PROGRAMMING_BACKGROUND_IMAGE_URL,
} from '../AgenciesBanner/AgenciesBanner.constants';
import { AgenciesBannerInfo } from '../AgenciesBanner/AgenciesBanner.types';
import { mapper, nameBuilderMapper } from './transformer';

const getNameByType = (type, urlParams) => {
    if (type === TYPES.NESTED_SUB_CATEGORY) {
        return nameBuilderMapper[type]({
            id: urlParams.id,
            subCategoryId: urlParams.subCategoryId,
        });
    } else {
        return nameBuilderMapper[type]({
            id: urlParams.id,
            subCategoryId: null,
        });
    }
};

export const getItemLink = (type, urlParams, source, locale) =>
    mapper[type] ? mapper[type](urlParams, source, locale) : '';

export const getItemName = (alias, type, urlParams) =>
    alias || (nameBuilderMapper[type] ? getNameByType(type, urlParams) : '');

export const createSourceInfo = ({ videoName, mixpanelPageName, bigQueryPageName }) => ({
    videoName,
    mixpanel: {
        pageName: mixpanelPageName,
    },
    bigQuery: {
        pageName: bigQueryPageName,
    },
});

export const createAgenciesBannerInfo = (id: number, verticalTitle: string): AgenciesBannerInfo | undefined => {
    switch (id) {
        case CATEGORIES_ID_MAPPER['programming-tech']:
            return {
                verticalId: id,
                verticalTitle,
                bgImageUrl: PROGRAMMING_BACKGROUND_IMAGE_URL,
                backgroundColor: 'pink_100',
                fontColor: 'pink_1200',
                descriptionKey: 'programming',
                tags: AGENCIES_PROGRAMMING_BANNER_TAG_KEYS,
                searchPath: pathfinder('agencies_software_development_content_page'),
            };
        case CATEGORIES_ID_MAPPER['online-marketing']:
            return {
                verticalId: id,
                verticalTitle,
                bgImageUrl: DIGITAL_MARKETING_BACKGROUND_IMAGE_URL,
                backgroundColor: 'red_100',
                fontColor: 'red_1200',
                descriptionKey: 'digital_marketing',
                tags: AGENCIES_DIGITAL_MARKETING_BANNER_TAG_KEYS,
                searchPath: pathfinder('agencies_digital_marketing_content_page'),
            };
        case CATEGORIES_ID_MAPPER['graphics-design']:
            const searchPathVA =
                '/categories/graphics-design/creative-logo-design?source=drop_down_filters&ref=is_agency%3Atrue';
            return {
                verticalId: id,
                verticalTitle,
                bgImageUrl: GRAPHICS_DESIGN_BACKGROUND_IMAGE_URL,
                backgroundColor: 'red_100',
                fontColor: 'red_1200',
                descriptionKey: 'graphics_design',
                tags: AGENCIES_GRAPHICS_DESIGN_BANNER_TAG_KEYS,
                searchPath: searchPathVA,
            };
        default:
            return undefined;
    }
};
