import { removeEmptyEntries } from '../../../lib/utils';
import mixpanelService from '../mixpanelService';
import localStorage from '../localStorage';
import { EVENTS_MAPPING } from '../../constants';

const prepareMixpanelTrackPayload = ({ id, title, payload = {} }) =>
    removeEmptyEntries({
        'Page Name': 'Category',
        'Category Id': id,
        'Category Name': title,
        ...payload,
    });

class Tracker {
    private context: any;
    initialize(context) {
        this.context = context;
    }

    trackHowFiverrWorksVideo(payload) {
        mixpanelService.trackEvent(
            EVENTS_MAPPING.CLICK.TARGETS.HOW_FIVERR_WORKS_VIDEO,
            prepareMixpanelTrackPayload({ ...this.context, payload })
        );
    }

    view(categorySlug, loggedIn) {
        mixpanelService.trackEvent(
            EVENTS_MAPPING.PAGE_VIEWED.mixpanel,
            prepareMixpanelTrackPayload({ ...this.context })
        );
        if (!loggedIn) {
            localStorage.storePageView(categorySlug);
        }
    }
}

export default new Tracker();
