import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_FIVERR_LOCALE, SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { getScreenAspectRatio } from '../../../../../../lib/utils/getScreenAspectRatio/getScreenAspectRatio';

const normalizeLocale = (locale) => (locale === 'es' ? SUPPORTED_FIVERR_LOCALES_KEYS.ES_ES : locale);

export const getVideoSourceByLocale = ({ sources = {} }) => {
    const { locale } = getContext();
    const normalizedLocale = normalizeLocale(locale);
    const screenAspectRatio = getScreenAspectRatio();

    return sources[normalizedLocale]?.[screenAspectRatio] || sources[DEFAULT_FIVERR_LOCALE][screenAspectRatio];
};
