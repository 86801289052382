import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { getContext } from '@fiverr-private/fiverr_context';
import { l_arrow_right } from '@fiverr-private/fit/icons';
import { getItemLink } from '../utils';
import { TYPES, FLP_TYPE } from '../../../lib/constants/verticalsData';
import eventReporter from '../../utils/reporter';
import { EVENT_TYPES } from '../../utils/reporter/constants';

import './index.scss';

interface MostPopularSlideProps {
    item: MostPopularLinksItem;
    componentProps: IVertical;
    index: number;
}

const MostPopularSlide = ({ item, componentProps, index }: MostPopularSlideProps) => {
    const { locale } = getContext();
    const { id: verticalId, title: verticalTitle } = componentProps;
    const { subCategoryId, nestedSubCategoryId, icon, title, type, leadingFilter } = item;
    const vertical_carousel_source = 'vertical-carousel';
    let itemLink;

    if (type === FLP_TYPE) {
        const urlParams = {
            categoryId: verticalId,
            id: subCategoryId,
            nestedSubCategoryId,
            filterId: leadingFilter,
        };
        itemLink = getItemLink(TYPES.FILTERED_PAGE, urlParams, null, locale);
    } else {
        const urlParams = {
            categoryId: verticalId,
            id: subCategoryId,
            subCategoryId,
            nestedSubCategoryId,
        };
        const itemType = nestedSubCategoryId ? TYPES.NESTED_SUB_CATEGORY : TYPES.SUB_CATEGORY;
        itemLink = getItemLink(itemType, urlParams, vertical_carousel_source, locale);
    }

    const handleMostPopularItemClick = () => {
        const payload = {
            'Item location': index + 1,
            'Item name': title,
            'Item URL': itemLink,
        };
        eventReporter.reportEvent(
            EVENT_TYPES.CLICKED_POPULAR_CAROUSEL_ITEM,
            eventReporter.mixpanelEventEnrichment(verticalId, verticalTitle, payload)
        );
    };

    return (
        <a href={itemLink} className="most-popular-slide box-shadow-z2 p-12" onClick={handleMostPopularItemClick}>
            <img className="m-r-12" src={icon} alt={title} />
            <span className="tbody-5 text-semi-bold m-r-12">{title}</span>
            <Icon className="arrow m-l-12">{l_arrow_right}</Icon>
        </a>
    );
};

export default MostPopularSlide;
