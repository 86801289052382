import pathfinder from '@fiverr-private/pathfinder';
import { translate } from '@fiverr-private/i18n-react';
import { PATHFINDER } from '../../../../../lib/constants/pathfinder';
import { PATHS } from '../../../../../lib/constants/verticalsData';

export const getStaticPagePath = ({ categoryId, id, nestedSubCategoryId, filterId }, source) => {
    if (!(categoryId && id && filterId)) {
        return PATHS.ROOT;
    }

    if (
        isNaN(parseInt(categoryId, 10)) ||
        isNaN(parseInt(id, 10)) ||
        (nestedSubCategoryId && parseInt(nestedSubCategoryId, 10))
    ) {
        return PATHS.ROOT;
    }

    const categorySlug = translate(`categories.category_${categoryId}.cached_slug`) || '';
    const subCategorySlug = translate(`sub_categories.sub_category_${id}.cached_slug`) || '';

    const params = {
        category_id: categorySlug,
        id: subCategorySlug,
        filters_uid: filterId,
    };
    const options = source ? { query: { source } } : {};

    if (nestedSubCategoryId) {
        const nestedSubCategorySlug =
            translate(`sub_categories.sub_category_${id}.nested_sub_cat_${nestedSubCategoryId}.cached_slug`) || '';

        const nestedSubCategory = {
            ...params,
            nested_sub_category_id: nestedSubCategorySlug,
        };

        return pathfinder(PATHFINDER.NESTED_SUB_CATEGORY_STATIC_PAGE, nestedSubCategory, options);
    }

    return pathfinder(PATHFINDER.SUB_CATEGORY_STATIC_PAGE, params, options);
};
