import { logger } from '@fiverr-private/obs';
import mixpanelService from '../mixpanelService';
import { EVENT_TYPES, MIXPANEL_EVENT_TITLES } from './constants';

class EventReporter {
    reportEvent(eventType, payload = {}) {
        if (Object.prototype.hasOwnProperty.call(EVENT_TYPES, eventType)) {
            mixpanelService.trackEvent(MIXPANEL_EVENT_TITLES[eventType], payload);
        } else {
            logger.warn(new Error(`Unknown action type: ${eventType} for event reporting`));
        }
    }

    mixpanelEventEnrichment = (verticalId, verticalTitle, payload) => ({
        'Page Name': 'Category',
        'Category Id': verticalId,
        'Category Name': verticalTitle,
        ...payload,
    });
}

export default new EventReporter();
