import pathfinder from '@fiverr-private/pathfinder';
import { translate } from '@fiverr-private/i18n-react';
import { PATHFINDER } from '../../../../../lib/constants/pathfinder';
import { PATHS } from '../../../../../lib/constants/verticalsData';

export const getCategoryPath = ({ id }, source) => {
    if (!id) {
        return PATHS.ROOT;
    }

    if (isNaN(parseInt(id, 10))) {
        return PATHS.ROOT;
    }

    const options = { query: { source } };
    const categorySlug = translate(`categories.category_${id}.cached_slug`);

    if (categorySlug) {
        const params = {
            id: categorySlug,
        };
        return pathfinder(PATHFINDER.CATEGORY, params, options);
    }

    return '';
};
