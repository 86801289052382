import React from 'react';
import { Typography } from '@fiverr-private/typography';
import { WrapItem } from '@fiverr-private/layout_components';

export const ProjectPartnerCategoryTag: React.FC = ({ children }) => (
    <WrapItem width="fit-content" borderRadius="circle" backgroundColor="orange_100">
        <Typography fontSize="b_sm" whiteSpace="nowrap" paddingX="3" paddingY="1" as="p" color="black">
            {children}
        </Typography>
    </WrapItem>
);
