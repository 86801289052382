import React from 'react';
import { Collapsible, Section } from '@fiverr-private/fit';
import eventReporter from '../../utils/reporter';
import { EVENT_TYPES } from '../../utils/reporter/constants';
import SeoMarkupScript, { FAQFormatter } from './SeoMarkup';

import './index.scss';

interface FaqsProps {
    title: string;
    faqs: FaqsType;
    verticalData: IVertical;
}

const Faqs = ({ title, faqs, verticalData }: FaqsProps) => {
    const { id: verticalId, title: verticalTitle } = verticalData;

    const onExpand = (payload) =>
        eventReporter.reportEvent(
            EVENT_TYPES.CLICKED_FAQ_EXPANDED,
            eventReporter.mixpanelEventEnrichment(verticalId, verticalTitle, payload)
        );
    const onMinimize = (payload) =>
        eventReporter.reportEvent(
            EVENT_TYPES.CLICKED_FAQ_COLLAPSED,
            eventReporter.mixpanelEventEnrichment(verticalId, verticalTitle, payload)
        );

    return (
        <div className="vertical-faqs">
            <Section className="faqs-section">
                <h2 className="text-display-4 vertical-faqs-title">{title}</h2>

                {faqs?.map(({ question, answer }, index) => {
                    const eventPayload = {
                        'FAQ Location': index + 1,
                        'FAQ Title': question,
                    };
                    return (
                        <Collapsible
                            key={index}
                            className="faq-item co-text-dark"
                            chevronClassName="faq-chevron"
                            titleClassName="faq-item-title"
                            contentClassName="faq-item-content"
                            title={question}
                            onExpand={() => onExpand(eventPayload)}
                            onMinimize={() => onMinimize(eventPayload)}
                        >
                            {answer}
                        </Collapsible>
                    );
                })}
            </Section>
            {faqs && <SeoMarkupScript scriptContent={FAQFormatter(faqs)} />}
        </div>
    );
};

export default Faqs;
