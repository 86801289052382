import React, { useState } from 'react';
import classNames from 'classnames';
import { Collapsible, Icon } from '@fiverr-private/fit';
import { grey_1100 } from '@fiverr-private/sass/helpers';
import { getContext } from '@fiverr-private/fiverr_context';
import { ArrowRightIcon } from '@fiverr-private/icons';
import Badge from '../../Badge';
import { BadgeMapping } from '../../Badge/constants';
import { getItemLink, getItemName } from '../../utils';
import { TYPES } from '../../../../lib/constants/verticalsData';
import imgPlaceHolder from '../../../assets/images/placeholder_image.png';
import eventReporter from '../../../utils/reporter';
import { EVENT_TYPES } from '../../../utils/reporter/constants';
import { verticalBucketsSource } from './constants';
import { baseEventPayload, scEventPayload } from './utils';

import './index.scss';

interface BucketTitleProps {
    bucketTitle: string;
    imgSrc: string;
}

const BucketTitle = ({ bucketTitle, imgSrc }: BucketTitleProps) => (
    <div className="bucket-top">
        <img className="img-wrapper" src={imgSrc} alt={bucketTitle} loading="lazy" />
        <h3 className="text-display-5 title-wrapper">{bucketTitle}</h3>
    </div>
);

interface BucketContentProps {
    isExpanded: boolean;
    bucketData: IBucketItems;
    verticalId: number;
    verticalTitle: string;
}

const BucketContent = ({ isExpanded, bucketData, verticalId, verticalTitle }: BucketContentProps) => {
    const { locale } = getContext();
    const { items = [] } = bucketData;

    const clickOnBucketItem = (payload) => eventReporter.reportEvent(EVENT_TYPES.CLICKED_SUB_CATEGORY_LINK, payload);

    return (
        <div
            className={classNames('items-content', {
                'is-expanded': isExpanded,
            })}
        >
            {items.map(({ title, badge, subCategoryId, nestedSubCategoryId, storeSlug }) => {
                const shouldDisplayBadge = badge && BadgeMapping[badge];
                let itemName = title;
                let itemLink;

                if (storeSlug) {
                    const urlParams = { slug: storeSlug };
                    itemLink = getItemLink(TYPES.CMS, urlParams, verticalBucketsSource, locale);
                } else {
                    const urlParams = {
                        categoryId: verticalId,
                        id: subCategoryId,
                        subCategoryId,
                        nestedSubCategoryId,
                    };
                    const itemType = nestedSubCategoryId ? TYPES.NESTED_SUB_CATEGORY : TYPES.SUB_CATEGORY;

                    itemName = getItemName(title, itemType, urlParams);
                    itemLink = getItemLink(itemType, urlParams, verticalBucketsSource, locale);
                }

                const eventPayload = eventReporter.mixpanelEventEnrichment(
                    verticalId,
                    verticalTitle,
                    scEventPayload({
                        bucketData,
                        scId: subCategoryId,
                        scName: title,
                    })
                );

                return (
                    <div key={title} className="item-wrapper">
                        <a
                            className={classNames('item-name', {
                                badge: shouldDisplayBadge,
                            })}
                            href={itemLink}
                            onClick={() => clickOnBucketItem(eventPayload)}
                        >
                            <div className="item-name-inner-link">
                                <span className="item-name-inner-link-text">{itemName}</span>
                                {badge && <Badge badge={badge} />}
                            </div>
                            <Icon className="item-name-arrow" color={grey_1100}>
                                <ArrowRightIcon />
                            </Icon>
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

interface BucketProps {
    bucketData: IBucketItems;
    verticalId: number;
    verticalTitle: string;
}

const Bucket = ({ bucketData, verticalId, verticalTitle }: BucketProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { title: bucketTitle, icon } = bucketData;
    const imgSrc = icon || imgPlaceHolder;
    const eventPayload = eventReporter.mixpanelEventEnrichment(
        verticalId,
        verticalTitle,
        baseEventPayload({ bucketData })
    );

    const onToggle = () => setIsExpanded(!isExpanded);

    const onExpand = () => eventReporter.reportEvent(EVENT_TYPES.CLICKED_BUCKET_EXPANDED, eventPayload);

    const onMinimize = () => eventReporter.reportEvent(EVENT_TYPES.CLICKED_BUCKET_COLLAPSED, eventPayload);

    return (
        <Collapsible
            className={classNames('collapsible', { 'is-expanded': isExpanded })}
            chevronWrapperClassName="chevron-wrapper"
            onToggle={onToggle}
            onExpand={onExpand}
            onMinimize={onMinimize}
            titleWrapperClassName="bucket-title-wrapper p-0"
            chevronClassName="bucket-chevron"
            titleClassName={classNames('bucket-title', {
                'is-expanded': isExpanded,
            })}
            contentClassName="bucket-content"
            title={<BucketTitle bucketTitle={bucketTitle} imgSrc={imgSrc} />}
        >
            <BucketContent
                isExpanded={isExpanded}
                bucketData={bucketData}
                verticalId={verticalId}
                verticalTitle={verticalTitle}
            />
        </Collapsible>
    );
};

export default Bucket;
