import type RouteKey from '@fiverr-private/pathfinder/routes';

export const PATHFINDER: Record<string, RouteKey> = {
    STORE: 'store',
    SEARCH: 'search_gigs',
    CATEGORY: 'short_category',
    PRO_CATEGORY: 'short_pro_category',
    PRO_SUB_CATEGORY: 'short_pro_sub_category',
    SUB_CATEGORY: 'short_category_sub_category',
    NESTED_SUB_CATEGORY: 'short_category_nested_sub_category',
    SUB_CATEGORY_STATIC_PAGE: 'sub_category_static_page',
    NESTED_SUB_CATEGORY_STATIC_PAGE: 'nested_sub_category_static_page',
    DANGEROUSLY_ALLOCATE: 'experiment_dangerously_allocate_api',
};
