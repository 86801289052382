import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import Bucket from './Bucket';

import './index.scss';

interface BucketsContainerProps {
    verticalData: IVertical;
}

const BucketsContainer = ({ verticalData }: BucketsContainerProps) => {
    const { id: verticalId, title: verticalTitle, buckets } = verticalData;
    const { items } = buckets;
    const shouldRenderContainer = items && items.length;

    if (!shouldRenderContainer) {
        return null;
    }

    return (
        <div className="buckets-container">
            <h2 className="text-display-4">
                <I18n k="vertical_page_perseus.buckets_title" params={{ verticalName: verticalTitle }} />
            </h2>

            <div className="buckets-container-wrapper">
                {items.map((bucket, index) => {
                    const { uniqueId } = bucket;
                    const bucketData = { ...bucket, position: index + 1 };

                    return (
                        <Bucket
                            key={uniqueId}
                            bucketData={bucketData}
                            verticalId={verticalId}
                            verticalTitle={verticalTitle}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default BucketsContainer;
