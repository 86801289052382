import React from 'react';
import classNames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import ExplanationVideo from '../video/ExplanationVideo';
import { sourceInfoType, videoType } from '../video/types';
import './index.scss';

interface HeroBannerProps {
    verticalData: IVertical;
    title: string;
    sourceInfo: sourceInfoType;
    clickOnHowFiverrWorks: () => void;
    video: videoType;
}

interface IBannerWrapperStyle extends React.CSSProperties {
    '--banner-background-color': string;
    '--mobile-background-img': string;
    '--desktop-background-img': string;
}

const HeroBanner = ({ verticalData, title, sourceInfo, clickOnHowFiverrWorks, video = {} }: HeroBannerProps) => {
    const { id: verticalId, bannerUrls, subtitle, bannerBgColor } = verticalData;
    const { mobile: mobileBannerUrl, desktop: desktopBannerUrl } = bannerUrls;

    const cssBgImgUrl = (bgImg) => `url('${bgImg}')`;
    const videoTitle = translate(video.titleKey) || '';

    const bannerWrapperStyle: IBannerWrapperStyle = {
        '--banner-background-color': bannerBgColor,
        '--mobile-background-img': cssBgImgUrl(mobileBannerUrl),
        '--desktop-background-img': cssBgImgUrl(desktopBannerUrl),
    };

    return (
        <div className="hero-banner-wrapper" style={bannerWrapperStyle}>
            <div className={classNames('hero-banner', `vertical-${verticalId}`)}>
                <div className="banner-content">
                    <h1 className="title">{title}</h1>
                    <p className="subtitle tbody-5">{subtitle}</p>
                    <ExplanationVideo
                        className="hero-banner-video"
                        onOpen={clickOnHowFiverrWorks}
                        video={video}
                        sourceInfo={sourceInfo}
                        videoTitle={videoTitle}
                    />
                </div>

                <div className="banner-quarter top-left" />
                <div className="banner-quarter bottom-right" />
                <div className="banner-quarter top-right" />
                <div className="banner-quarter bottom-left" />
            </div>
        </div>
    );
};

export default HeroBanner;
