import { merge } from 'lodash';
import { bigQuery } from '@fiverr-private/obs';

class TrackingService {
    baseReportData: any;
    constructor() {
        this.sendBQEvent = this.sendBQEvent.bind(this);
    }

    public setTrackerReportData(data: any) {
        this.baseReportData = data;
    }

    sendBQEvent(params = {}) {
        bigQuery.send(merge({}, this.baseReportData, params));
    }
}

const tracker = new TrackingService();

export default tracker;
