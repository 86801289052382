import { DirectionType, ScrollType } from './types';

export const DEFAULT_BREAKPOINTS = { xs: 2, sm: 3, md: 4, lg: 5 };
export const DEFAULT_SLIDES_TO_SHOW = 5;

export const DIRECTIONS: Record<string, DirectionType> = {
    LEFT: 'left',
    RIGHT: 'right',
};

export const SCROLL_TYPE: Record<string, ScrollType> = {
    SINGLE: 'single',
    ROW: 'row',
};
