import React from 'react';
import { UnionIcon } from '@fiverr-private/icons';
import { Container } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';

export const ProjectPartnerTag = () => (
    <Container
        paddingX="2"
        display="flex"
        paddingTop="0.5"
        borderRadius="circle"
        borderWidth="sm"
        borderColor="black"
        alignItems="center"
        width="fit-content"
    >
        <UnionIcon size={12} color="black" />
        <Text marginLeft="1" color="bodyPrimary" size="b_sm">
            <I18n k="vertical_page_perseus.project_partner_banner.project_management" />
        </Text>
    </Container>
);
