import sanitizeSeo from '../sanitize';

const FAQFormatter = (faqs: FaqsType) => {
    const mainEntity = faqs.map((item) => ({
        '@type': 'Question',
        name: item.question,
        acceptedAnswer: {
            '@type': 'Answer',
            text: item.answer,
        },
    }));

    const faqsScript = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity,
    };

    return sanitizeSeo(faqsScript);
};

export default FAQFormatter;
