const COLORS: BadgeColors = {
    BLUE: 'blue',
    PINK: 'pink',
};

export const TAGS = {
    NEW: 'New',
    BETA: 'Beta',
};

const THEMES: BadgeThemes = {
    FULL: 'full',
    HOLLOW: 'hollow',
};

export const BadgeMapping = {
    [TAGS.NEW]: {
        config: {
            color: COLORS.PINK,
            text: TAGS.NEW,
            theme: THEMES.HOLLOW,
        },
    },
    [TAGS.BETA]: {
        config: {
            color: COLORS.BLUE,
            text: TAGS.BETA,
            theme: THEMES.HOLLOW,
        },
    },
};
