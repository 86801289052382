import React, { useEffect } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { Card, Container, Stack, Wrap, WrapItem } from '@fiverr-private/layout_components';
import { Heading, Text, Typography } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { Image } from '@fiverr-private/media';
import { BQ_EVENTS } from '../../utils/reporter/constants';
import BigQuery from '../../utils/bigQuery';
import { CATEGORIES_ID_MAPPER } from '../../../lib/constants/verticalsData';
import { ProjectPartnerCategoryTag } from './ProjectPartnerCategoryTag';
import { ProjectPartnerTag } from './ProjectPartnerTag';
import { PROJECT_PARTNER_IMAGE_URL } from './ProjectPartnerBanner.constants';
import { imageStack, imageWrapper } from './ProjectPartnerBanner.styles.ve.css';

interface ProjectPartnerBannerProps {
    verticalId: number;
    verticalTitle: string;
}

const REDIRECT_SOURCE_MAPPING = {
    [CATEGORIES_ID_MAPPER['programming-tech']]: 'vertical_page_p&t',
    [CATEGORIES_ID_MAPPER['online-marketing']]: 'vertical_page_dm',
    [CATEGORIES_ID_MAPPER['graphics-design']]: 'vertical_page_g&d',
    [CATEGORIES_ID_MAPPER['video-animation']]: 'vertical_page_v&a',
    [CATEGORIES_ID_MAPPER['writing-translation']]: 'vertical_page_w&t',
};
const GRADIENT_BACKGROUND = 'linear-gradient(90deg, rgba(246, 218, 207, 1), rgba(246, 218, 207, 0.4))';

const TAGS_BY_CATEGORY = {
    [CATEGORIES_ID_MAPPER['programming-tech']]: ['website_development', 'commerce_development'],
    [CATEGORIES_ID_MAPPER['online-marketing']]: ['social_media_marketing', 'marketing_strategy', 'seo_sem'],
    [CATEGORIES_ID_MAPPER['graphics-design']]: ['website_design', 'social_media_design', 'book_design'],
    [CATEGORIES_ID_MAPPER['video-animation']]: ['video_editing', 'animated_explainer', 'social_media_videos'],
    [CATEGORIES_ID_MAPPER['writing-translation']]: ['ebooks', 'website_content', 'articles_and_blogs'],
};

const mapVerticalIdToPageSlug = (verticalId: number) => {
    switch (verticalId) {
        case CATEGORIES_ID_MAPPER['programming-tech']:
            return 'tech-project-management';
        default:
            return 'project-partner';
    }
};

export const ProjectPartnerBanner: React.FC<ProjectPartnerBannerProps> = ({ verticalId, verticalTitle }) => {
    const { experience } = getContext();
    const source = REDIRECT_SOURCE_MAPPING[verticalId] || 'vertical_page';
    const querySource = experience?.isBusiness ? `pro_${source}` : source;
    const tags = TAGS_BY_CATEGORY[verticalId] || [];
    const pageSlug = mapVerticalIdToPageSlug(verticalId);
    const projectPartnerPageHref = pathfinder(
        'content_pages_page',
        { page_slug: pageSlug },
        { query: { source: querySource } }
    );

    useEffect(() => {
        BigQuery.sendBQEvent(
            {
                type: BQ_EVENTS.VIEWED_PROJECT_PARTNER_BANNER,
                pageName: 'vertical_page_perseus',
                group: 'user_impressions',
                actionType: 'impression',
            },
            {
                page: {
                    component: {
                        name: 'pjm_vertical_banner',
                        type: 'banner',
                    },
                    element: {
                        id: verticalId,
                        name: verticalTitle,
                    },
                },
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card borderRadius="lg">
            <Container display="flex" direction="row" justifyContent="spaceBetween" gap="8">
                <Stack
                    direction="column"
                    padding="8"
                    gap="6"
                    background={{ md: 'transparent', default: GRADIENT_BACKGROUND }}
                    width={{ default: '100%', md: 'auto' }}
                >
                    <Stack direction="column" gap="2" maxWidth="564px">
                        <Container display={{ default: 'initial', md: 'none' }} marginBottom="2">
                            <ProjectPartnerTag />
                        </Container>
                        <Heading fontWeight="bold" as="h4" size={{ default: 'h_lg', md: 'h_sm', lg: 'h_md' }}>
                            <I18n
                                k="vertical_page_perseus.project_partner_banner.title"
                                params={{
                                    category: verticalTitle,
                                }}
                            />
                        </Heading>
                        <Text as="p" size={{ default: 'b_md', md: 'b_lg' }}>
                            <I18n k="vertical_page_perseus.project_partner_banner.description" />
                        </Text>
                    </Stack>
                    <Wrap direction="row" alignItems="center" gap="2">
                        {tags.map((tag) => (
                            <ProjectPartnerCategoryTag key={tag}>
                                <I18n k={`vertical_page_perseus.project_partner_banner.tags.${tag}`} />
                            </ProjectPartnerCategoryTag>
                        ))}
                        <WrapItem alignItems="center">
                            <Typography whiteSpace="nowrap" fontWeight="semibold" as="p" size="b_sm" color="black">
                                <I18n k="vertical_page_perseus.project_partner_banner.and_more" />
                            </Typography>
                        </WrapItem>
                    </Wrap>
                    <Container>
                        <Button size="sm" href={projectPartnerPageHref}>
                            <I18n k="vertical_page_perseus.project_partner_banner.cta" />
                        </Button>
                    </Container>
                </Stack>
                <Container
                    className={imageStack}
                    minWidth={{ default: '350px', lg: '450px' }}
                    alignItems="center"
                    display={{ default: 'none', md: 'flex' }}
                    background={GRADIENT_BACKGROUND}
                    position="relative"
                    marginLeft={{ default: '5', xl: '3' }}
                    minHeight={{ default: '294px', lg: 'unset' }}
                >
                    <Container
                        boxShadow="z1"
                        className={imageWrapper}
                        borderRadius="md"
                        marginY={{ default: 'auto', lg: '8' }}
                        marginX="auto"
                        width={{ md: '262px', lg: '310px' }}
                        height={{ md: '176px', lg: '208px' }}
                    >
                        <Image alt="banner image" src={PROJECT_PARTNER_IMAGE_URL} />
                    </Container>
                    <Container position="absolute" right="16px" bottom="16px">
                        <ProjectPartnerTag />
                    </Container>
                </Container>
            </Container>
        </Card>
    );
};
