import { last } from 'lodash';
import { logger } from '@fiverr-private/obs';
import { DIRECTIONS, SCROLL_TYPE } from './constants';

const addTransition = (slidesTrackRef) => {
    slidesTrackRef.current.style.transition = 'transform 600ms ease';
};

const findFirstOverflowedSlideFromRight = (slidesListPosition, slides) => {
    let overflowedFromTheRight;

    for (const slide of slides) {
        const slideRect = slide.getBoundingClientRect();

        if (slidesListPosition.right < slideRect.right) {
            overflowedFromTheRight = slideRect;
            break;
        }
    }
    return overflowedFromTheRight;
};

const findFirstOverflowedSlideFromLeft = (slidesListPosition, slides) => {
    let overflowedFromTheLeft;

    for (let i = slides.length - 1; i >= 0; i--) {
        const slideRect = slides[i].getBoundingClientRect();
        if (slidesListPosition.left > slideRect.left) {
            overflowedFromTheLeft = slideRect;
            break;
        }
    }
    return overflowedFromTheLeft;
};

const findSecondVisibleSlideFromTheLeft = (slidesListPosition, slides) => {
    let secondVisibleSlide;
    for (const slide of slides) {
        const slideRect = slide.getBoundingClientRect();

        if (slideRect.left > slidesListPosition.left) {
            secondVisibleSlide = slideRect;
            break;
        }
    }
    return secondVisibleSlide;
};

export const removeAnimation = (slidesTrackRef, setIsAnimating) => {
    slidesTrackRef.current.style.removeProperty('transition');
    setIsAnimating(false);
};

export const setTransformValue = (slidesTrackRef, carouselTransformValue) => {
    slidesTrackRef.current.style.transform = `translate3d(${carouselTransformValue.current}px, 0, 0)`;
};

export const createCarouselBreakpoints = (breakpoints, style) => {
    Object.keys(breakpoints).forEach((key) => {
        style[`--bp-${key}`] = breakpoints[key];
    });
};

export const buildUtilFunctions = ({
    isAnimating,
    isInfinite,
    setIsAnimating,
    slidesTrackRef,
    carouselTransformValue,
    scrolledSlidesStack,
    slidesListRef,
    scrollType,
    canScrollRight,
    canScrollLeft,
    setCanScrollLeft,
    setCanScrollRight,
}) => {
    const dragSlideByType = {
        [SCROLL_TYPE.SINGLE]: ({ slidesListPosition, slidesTrackPosition, slides, direction }) => {
            handleSingleScroll({
                slidesListPosition,
                slidesTrackPosition,
                slides,
            })[direction]();
        },
        [SCROLL_TYPE.ROW]: ({ slidesListPosition, slidesTrackPosition, slides, direction }) => {
            handleRowScroll({
                slidesListPosition,
                slidesTrackPosition,
                slides,
            })[direction]();
        },
    };

    const scrollRight = () => {
        const { left }: any = last(scrolledSlidesStack.current) || {};

        if (left) {
            carouselTransformValue.current = carouselTransformValue.current + left;
            scrolledSlidesStack.current.pop();
        } else {
            const slidesListPosition = slidesListRef.current.getBoundingClientRect();
            const slidesTrackPosition = slidesTrackRef.current.getBoundingClientRect();
            const slides = slidesTrackRef.current.children;

            if (isInfinite && !canScrollRight) {
                const firstSlide = slides[0].getBoundingClientRect();
                dragSlideToMostLeft({
                    slideRect: firstSlide,
                    slidesListPosition,
                    slidesTrackPosition,
                    direction: DIRECTIONS.RIGHT,
                });
                return;
            }

            dragSlideByType[scrollType]({
                slidesListPosition,
                slidesTrackPosition,
                slides,
                direction: DIRECTIONS.RIGHT,
            });
        }
    };

    const scrollLeft = () => {
        const { right }: any = last(scrolledSlidesStack.current) || {};

        if (right) {
            carouselTransformValue.current = carouselTransformValue.current + right;
            scrolledSlidesStack.current.pop();
        } else {
            const slidesListPosition = slidesListRef.current.getBoundingClientRect();
            const slidesTrackPosition = slidesTrackRef.current.getBoundingClientRect();
            const slides = slidesTrackRef.current.children;

            if (isInfinite && !canScrollLeft) {
                const slideRect = slides[slides.length - 1].getBoundingClientRect();
                dragSlideToMostRight({
                    slideRect,
                    slidesListPosition,
                    slidesTrackPosition,
                    direction: DIRECTIONS.LEFT,
                });
                return;
            }

            dragSlideByType[scrollType]({
                slidesListPosition,
                slidesTrackPosition,
                slides,
                direction: DIRECTIONS.LEFT,
            });
        }
    };

    const moveSlide = ({ carouselTransformValue, amountToMove, direction }) => {
        carouselTransformValue.current = carouselTransformValue.current - amountToMove;
        scrolledSlidesStack.current.push({ [direction]: amountToMove });
    };

    const dragSlideToMostLeft = ({ slideRect, slidesListPosition, slidesTrackPosition, direction }) => {
        let amountToMove = slideRect.left - slidesListPosition.left;

        const isLastScroll =
            slidesTrackRef.current.scrollWidth +
                carouselTransformValue.current -
                amountToMove -
                slidesTrackPosition.width <
            1;

        if (isLastScroll) {
            amountToMove =
                carouselTransformValue.current + (slidesTrackRef.current.scrollWidth - slidesTrackPosition.width);
        }

        moveSlide({ carouselTransformValue, amountToMove, direction });
    };

    const dragSlideToMostRight = ({ slideRect, slidesListPosition, slidesTrackPosition, direction }) => {
        let amountToMove = slideRect.right - slidesListPosition.right;

        const isLastScroll = slidesTrackPosition.left - amountToMove >= 0;

        if (isLastScroll) {
            amountToMove = carouselTransformValue.current;
        }

        moveSlide({ carouselTransformValue, amountToMove, direction });
    };

    const handleSingleScroll = ({ slidesListPosition, slidesTrackPosition, slides }) => ({
        [DIRECTIONS.LEFT]: () => {
            const overflowedFromLeft = findFirstOverflowedSlideFromLeft(slidesListPosition, slides);
            dragSlideToMostLeft({
                slideRect: overflowedFromLeft,
                slidesListPosition,
                slidesTrackPosition,
                direction: DIRECTIONS.LEFT,
            });
        },
        [DIRECTIONS.RIGHT]: () => {
            const secondVisibleSlide = findSecondVisibleSlideFromTheLeft(slidesListPosition, slides);
            dragSlideToMostLeft({
                slideRect: secondVisibleSlide,
                slidesListPosition,
                slidesTrackPosition,
                direction: DIRECTIONS.RIGHT,
            });
        },
    });

    const handleRowScroll = ({ slidesListPosition, slidesTrackPosition, slides }) => ({
        [DIRECTIONS.LEFT]: () => {
            const overflowedFromLeft = findFirstOverflowedSlideFromLeft(slidesListPosition, slides);
            dragSlideToMostRight({
                slideRect: overflowedFromLeft,
                slidesListPosition,
                slidesTrackPosition,
                direction: DIRECTIONS.LEFT,
            });
        },
        [DIRECTIONS.RIGHT]: () => {
            const overflowedFromRight = findFirstOverflowedSlideFromRight(slidesListPosition, slides);
            dragSlideToMostLeft({
                slideRect: overflowedFromRight,
                slidesListPosition,
                slidesTrackPosition,
                direction: DIRECTIONS.RIGHT,
            });
        },
    });

    const onScroll = (direction) => {
        try {
            if (isAnimating) {
                return;
            }

            setIsAnimating(true);
            addTransition(slidesTrackRef);
            if (direction === DIRECTIONS.RIGHT) {
                scrollRight();
            } else {
                scrollLeft();
            }
            checkTrackPosition();
            setTransformValue(slidesTrackRef, carouselTransformValue);
        } catch (err) {
            const clientProperties = {
                screenDimensions: window.innerWidth,
                slidesListRect: slidesListRef.current.getBoundingClientRect(),
                slidesTrackRect: slidesTrackRef.current.getBoundingClientRect(),
                scrolledSlidesStack: scrolledSlidesStack.current,
            };

            logger.warn(err as Error, { description: clientProperties });
        }
    };

    const setCanScrollByPosition = (isTrackOnPosition, setCanScroll) => {
        if (isTrackOnPosition) {
            setCanScroll(false);
        } else {
            setCanScroll(true);
        }
    };

    const checkTrackPosition = () => {
        const trackRightPosition = slidesTrackRef.current.offsetWidth - slidesTrackRef.current.scrollWidth;

        const isTrackOnRightPosition = Math.floor(carouselTransformValue.current) <= trackRightPosition;
        const isTrackOnLeftPosition = carouselTransformValue.current === 0;

        setCanScrollByPosition(isTrackOnLeftPosition, setCanScrollLeft);
        setCanScrollByPosition(isTrackOnRightPosition, setCanScrollRight);
    };

    return { onScroll, checkTrackPosition };
};
