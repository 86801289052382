export const UNKNOWN = 'UNKNOWN';
export const UNDEFINED = 'undefined';

export class MaxiosError extends Error {
    code: number;
    response: Record<string, any>;

    constructor() {
        super();
        this.code = 404;
        this.response = {};
    }
}
