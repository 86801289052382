const VERTICAL_PAGE = 'Vertical Page';

export const MIXPANEL_EVENT_TITLES = {
    CLICKED_SUB_CATEGORY_LINK: `${VERTICAL_PAGE} - Clicked SC link`,
    CLICKED_BUCKET_EXPANDED: `${VERTICAL_PAGE} - Clicked bucket expanded`,
    CLICKED_BUCKET_COLLAPSED: `${VERTICAL_PAGE} - Clicked bucket collapsed`,
    CLICKED_HOW_FIVERR_WORKS: 'How Fiverr Works video in Category',
    CLICKED_POPULAR_CAROUSEL_ITEM: `${VERTICAL_PAGE} - Clicked Popular Carousel Item`,
    CLICKED_FAQ_EXPANDED: `${VERTICAL_PAGE} - Clicked FAQ Expanded`,
    CLICKED_FAQ_COLLAPSED: `${VERTICAL_PAGE} - Clicked FAQ Collapsed`,
    CLICKED_FLP_LINK: `${VERTICAL_PAGE} - Clicked FLP link`,
    CLICKED_AGENCIES_BANNER: `${VERTICAL_PAGE} - Clicked Agencies Banner`,
};

export const EVENT_TYPES = {
    CLICKED_SUB_CATEGORY_LINK: 'CLICKED_SUB_CATEGORY_LINK',
    CLICKED_BUCKET_EXPANDED: 'CLICKED_BUCKET_EXPANDED',
    CLICKED_BUCKET_COLLAPSED: 'CLICKED_BUCKET_COLLAPSED',
    CLICKED_HOW_FIVERR_WORKS: 'CLICKED_HOW_FIVERR_WORKS',
    CLICKED_POPULAR_CAROUSEL_ITEM: 'CLICKED_POPULAR_CAROUSEL_ITEM',
    CLICKED_FAQ_EXPANDED: 'CLICKED_FAQ_EXPANDED',
    CLICKED_FAQ_COLLAPSED: 'CLICKED_FAQ_COLLAPSED',
    CLICKED_FLP_LINK: 'CLICKED_FLP_LINK',
    CLICKED_AGENCIES_BANNER: 'CLICKED_AGENCIES_BANNER',
    CLICKED_PROJECT_PARTNER_BANNER: 'CLICKED_PROJECT_PARTNER_BANNER',
};

export enum BQ_EVENTS {
    HOVER_ON_DISCOVER_AGENCIES_BANNER = 'hover_on_discover_agencies_banner',
    HOVER_ON_DISCOVER_AGENCIES_BUTTON = 'hover_on_discover_agencies_button',
    USER_CLICKED_ON_DISCOVER_AGENCIES_BUTTON = 'user_clicked_on_discover_agencies_button',
    VIEWED_PROJECT_PARTNER_BANNER = 'viewed_pjm_vertical_banner_vertical_page_perseus',
}
