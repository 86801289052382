import React from 'react';
import './index.scss';

interface MostPopularHeaderProps {
    title?: string;
}

const MostPopularHeader = ({ title }: MostPopularHeaderProps) => (
    <div className="most-popular-header">
        <h2 className="text-display-4">{title}</h2>
    </div>
);

export default MostPopularHeader;
