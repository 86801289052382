import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { VideoSourcesMap } from '../../../lib/types/video';

export const GENERAL_VIDEO_MODAL_NAME = 'general-video-modal';
export const VIDEO_TYPE = 'video/mp4';

export const IDS = {
    DEFAULT: 'default',
};

export const HOW_FIVERR_WORKS = {
    id: IDS.DEFAULT,
    name: 'How fiverr works? - general',
    titleKey: 'vertical_page_perseus.how_fiverr_works',
    sources: {
        [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/0e7e8378f90eddda63a8953a16bb68fa-1705932024206/How%20Fiverr%20Works%20EN%20Subs%2016x9',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/0e7e8378f90eddda63a8953a16bb68fa-1705932024204/How%20Fiverr%20Works%20EN%20Subs%204x5',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475712/How%20Fiverr%20Works%20DE%20Subs%2016x9',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475706/How%20Fiverr%20Works%20DE%20Subs%204x5',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.ES_ES]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475723/How%20Fiverr%20Works%20ES%20Subs%2016x9',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475703/How%20Fiverr%20Works%20ES%20Subs%204x5',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475726/How%20Fiverr%20Works%20FR%20Subs%2016x9',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475719/How%20Fiverr%20Works%20FR%20Subs%204x5',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.IT_IT]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475725/How%20Fiverr%20Works%20IT%20Subs%2016x9',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475711/How%20Fiverr%20Works%20IT%20Subs%204x5',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.NL_NL]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475708/How%20Fiverr%20Works%20NL%20Subs%2016x9',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475709/How%20Fiverr%20Works%20NL%20Subs%204x5',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.PT_BR]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475702/How%20Fiverr%20Works%20PT%20Subs%2016x9',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/d6740fcb4a724154066e6699dc5da127-1706094475711/How%20Fiverr%20Works%20PT%20Subs%204x5',
        },
    } satisfies VideoSourcesMap,
};

export const HOW_FIVERR_BUSINESS_WORKS = {
    id: IDS.DEFAULT,
    name: 'How Fiverr Business works? - general',
    titleKey: 'vertical_page_perseus.how_fiverr_business_works',
    sources: {
        [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.ES_ES]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.IT_IT]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.NL_NL]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
        },
        [SUPPORTED_FIVERR_LOCALES_KEYS.PT_BR]: {
            WIDESCREEN:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
            PORTRAIT:
                'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/9a426467eeedc010b4b2af9e3a6c2ed9-1690892131466/FiB%20Product%20Explainer_Long%20version_Eng%20subs',
        },
    } satisfies VideoSourcesMap,
};
