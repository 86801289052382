import pathfinder from '@fiverr-private/pathfinder';
import { translate } from '@fiverr-private/i18n-react';
import { PATHFINDER } from '../../../../../lib/constants/pathfinder';
import { PATHS } from '../../../../../lib/constants/verticalsData';

export const getSubCategoryPath = ({ categoryId, id }, source, locale) => {
    if (!(categoryId && id)) {
        return PATHS.ROOT;
    }

    if (isNaN(parseInt(categoryId, 10)) || isNaN(parseInt(id, 10))) {
        return PATHS.ROOT;
    }

    const categorySlug = translate(`categories.category_${categoryId}.cached_slug`, locale) || '';
    const subCategorySlug = translate(`sub_categories.sub_category_${id}.cached_slug`, locale) || '';
    const params = {
        category_id: categorySlug,
        id: subCategorySlug,
    };

    const options = { query: { source } };

    return categorySlug && subCategorySlug ? pathfinder(PATHFINDER.SUB_CATEGORY, params, options) : '';
};
