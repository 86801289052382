import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Base } from '@fiverr-private/modal/src';
import { Button, Icon } from '@fiverr-private/fit';
import { PlayOutlineSolidIcon } from '@fiverr-private/icons';
import tracker from '../../../utils/tracker';
import ModalBody from '../ModalBody';
import { GENERAL_VIDEO_MODAL_NAME } from '../constants';
import { sourceInfoType, videoType } from '../types';

import './index.scss';

interface subcatType {
    categoryName?: string;
    title?: string;
    subtitle?: string;
}
interface ExplanationVideoProps {
    subcat?: subcatType;
    hasBorder?: boolean;
    onOpen: () => void;
    className: string;
    video: videoType;
    videoTitle: string;
    sourceInfo: sourceInfoType;
    showButton?: boolean;
}

const ExplanationVideo = ({
    subcat = {},
    hasBorder = true,
    onOpen = noop,
    className,
    video,
    videoTitle,
    sourceInfo,
    showButton = true,
}: ExplanationVideoProps) => {
    const handleOpen = () => {
        if (onOpen !== noop) {
            onOpen();
        } else {
            const { categoryName = '', title = '' }: subcatType = subcat;
            tracker.trackHowFiverrWorksVideo({
                categoryName,
                subcategoryName: title,
            });
        }
    };

    const playBtnClass = classNames('btn-play', {
        'without-border': !hasBorder,
    });

    return (
        <div className={classNames('explanation-video', className)}>
            {subcat.subtitle && <p className="sc-subtitle">{subcat?.subtitle}</p>}
            {showButton && (
                <Base
                    name={GENERAL_VIDEO_MODAL_NAME}
                    onOpen={handleOpen}
                    trigger={({ open }) => (
                        <Button className={playBtnClass} size={'small'} onClick={open}>
                            <Icon size={14} className="play-icon">
                                <PlayOutlineSolidIcon />
                            </Icon>
                            {videoTitle}
                        </Button>
                    )}
                >
                    {() => <ModalBody video={video} sourceInfo={sourceInfo} />}
                </Base>
            )}
        </div>
    );
};

export default ExplanationVideo;
