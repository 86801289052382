import { translate } from '@fiverr-private/i18n-react';
import { TYPES } from '../../../../lib/constants/verticalsData';
import { getCategoryPath } from './items/category';
import { getSubCategoryPath } from './items/subCategory';
import { getStorePath } from './items/store';
import { getSearchQueryPath } from './items/searchQuery';
import { getStaticPagePath } from './items/filteredPage';
import { getNestedSubCategoryPath } from './items/nestedSubCategory';

export const mapper = {
    [TYPES.CATEGORY]: getCategoryPath,
    [TYPES.SUB_CATEGORY]: getSubCategoryPath,
    [TYPES.CMS]: getStorePath,
    [TYPES.SEARCH_QUERY]: getSearchQueryPath,
    [TYPES.FILTERED_PAGE]: getStaticPagePath,
    [TYPES.NESTED_SUB_CATEGORY]: getNestedSubCategoryPath,
};

export const nameBuilderMapper = {
    [TYPES.CATEGORY]: ({ id }) => translate(`categories.category_${id}.name`),
    [TYPES.SUB_CATEGORY]: ({ id }) => translate(`sub_categories.sub_category_${id}.name`),
    [TYPES.FILTERED_PAGE]: ({ id }) => translate(`sub_categories.sub_category_${id}.name`),
    [TYPES.NESTED_SUB_CATEGORY]: ({ id, subCategoryId }) =>
        translate(`sub_categories.sub_category_${subCategoryId}.nested_sub_cat_${id}.name`),
};
