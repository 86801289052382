import React from 'react';
import { Video } from '@fiverr-private/orca';
import { VIDEO_TYPE } from '../constants';
import { IVideo } from '../types';
import { getVideoSourceByLocale } from './services/getVideoSourceByLocale';

interface ModalBodyProps extends IVideo {}

const ModalBody = ({ video, sourceInfo }: ModalBodyProps) => {
    const sources = [
        {
            src: getVideoSourceByLocale(video),
            type: VIDEO_TYPE,
        },
    ];

    const videoProps = {
        width: '100%',
        height: '100%',
        controls: true,
        autoPlay: true,
        source: sources,
        sourceInfo,
    };

    return <Video ref={null} {...videoProps} />;
};

export default ModalBody;
