import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Tag } from '@fiverr-private/fit';
import { BadgeMapping } from './constants';

interface BadgeProps {
    badge: BadgeType;
}

const Badge = ({ badge }: BadgeProps) => {
    const mapping = BadgeMapping[badge];
    if (!mapping) {
        return null;
    }

    const {
        config: { text, color, theme },
    } = mapping;

    return (
        <Tag className="badge" theme={theme} color={color}>
            <I18n k={`vertical_page_perseus.badge.${text.toLowerCase()}`} />
        </Tag>
    );
};

export default Badge;
