import { SOURCE_PARAMS } from '../constants/sourceParams';
import { UNDEFINED, UNKNOWN } from '../constants';

export const isBrowser = () => typeof window !== UNDEFINED && typeof window.document !== UNDEFINED;

export const getValidSourceParam = (source) => (SOURCE_PARAMS.includes(source) ? source : UNKNOWN);

export const removeEmptyEntries = (params = {}) => {
    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (value === null || value === undefined || value === '') {
            delete params[key];
        } else if (Array.isArray(value) && value.length === 0) {
            delete params[key];
        }
    });

    return params;
};
