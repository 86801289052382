import { env } from '@fiverr-private/futile/';
import { localStorage } from '@fiverr-private/futile/lib/storage/localStorage';

const KEYS = {
    BROWSER_HISTORY: 'browse-history',
};

const defaultStorage = {
    [KEYS.BROWSER_HISTORY]: [],
};

const format = (categorySlug) => `|||categories|||show|||${categorySlug}`;

const get = (key) => {
    if (!env.browser) {
        return defaultStorage[key];
    }

    return localStorage.get(key) || defaultStorage[key];
};

const set = (key, value) => {
    if (env.browser) {
        return localStorage.set(key, value);
    }
};

const storePageView = (categorySlug) => {
    const formattedCategorySlug = format(categorySlug);
    const filteredBrowseHistory = get(KEYS.BROWSER_HISTORY).filter((entry) => !entry.includes(formattedCategorySlug));

    const newEntry = Date.now() + formattedCategorySlug;
    filteredBrowseHistory.push(newEntry);

    return set(KEYS.BROWSER_HISTORY, filteredBrowseHistory);
};

export default { get, set, storePageView, KEYS };
