export const EVENTS_MAPPING = {
    PAGE_VIEWED: {
        mixpanel: 'Viewed page',
    },
    PAGE_CHANGE: {
        mixpanel: 'Paginate',
    },
    CLICK: {
        mixpanel: 'Clicked',
        TARGETS: {
            HOW_FIVERR_WORKS_VIDEO: 'How Fiverr Works video In Category',
        },
    },
};

export const BI_CONSTS = {
    mixpanel: {
        pageName: 'Category',
    },
    bigQuery: {
        pageName: 'category',
    },
    pageView: {
        pageName: 'categories.show',
    },
};
