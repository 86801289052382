import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { l_chevron_left, l_chevron_right } from '@fiverr-private/fit/icons';
import { DIRECTIONS, DEFAULT_BREAKPOINTS, DEFAULT_SLIDES_TO_SHOW, SCROLL_TYPE } from './constants';
import { removeAnimation, setTransformValue, createCarouselBreakpoints, buildUtilFunctions } from './utils';
import { BreakpointsType, ScrollType } from './types';

import './index.scss';

interface CarouselProps {
    slidesToShow?: number;
    slides: MostPopularLinksItem[];
    breakpoints?: Record<BreakpointsType, number>;
    componentProps: IVertical;
    scrollType?: ScrollType;
    dynamicSlidesWidth?: boolean;
    isInfinite?: boolean;
    Header?: JSX.Element;
    carouselClassname: string;
    Component: React.ElementType;
    slideClassname?: string;
}

const Carousel = (props: CarouselProps) => {
    const {
        slidesToShow = DEFAULT_SLIDES_TO_SHOW,
        slides = [],
        breakpoints = DEFAULT_BREAKPOINTS,
        scrollType = SCROLL_TYPE.SINGLE,
        dynamicSlidesWidth = false,
        componentProps,
        carouselClassname = '',
        slideClassname = '',
        isInfinite = false,
        Header,
        Component,
    } = props;

    const slidesTrackRef = useRef(null);
    const slidesListRef = useRef(null);
    const scrolledSlidesStack = useRef([]);
    const carouselTransformValue = useRef(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(slides.length > slidesToShow);
    const [canScrollLeft, setCanScrollLeft] = useState(false);

    const utils = buildUtilFunctions({
        isAnimating,
        isInfinite,
        canScrollRight,
        canScrollLeft,
        slidesTrackRef,
        carouselTransformValue,
        scrolledSlidesStack,
        slidesListRef,
        scrollType,
        setIsAnimating,
        setCanScrollLeft,
        setCanScrollRight,
    });

    const { onScroll, checkTrackPosition } = utils;

    const style = {};
    createCarouselBreakpoints(breakpoints, style);

    useEffect(() => {
        const handleResize = () => {
            scrolledSlidesStack.current = [];
            carouselTransformValue.current = 0;
            setTransformValue(slidesTrackRef, carouselTransformValue);
            setCanScrollRight(slides.length > slidesToShow);
            setCanScrollLeft(false);
            checkTrackPosition();
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classNames('vertical-carousel-wrapper', carouselClassname)}>
            <div className="controls-wrapper">
                {Header}
                <div className="buttons-wrapper">
                    <button
                        className="carousel-btn left-arrow box-shadow-z1"
                        disabled={!isInfinite && !canScrollLeft}
                        onClick={() => onScroll(DIRECTIONS.LEFT)}
                    >
                        <Icon size={12} className="chevron-icon">
                            {l_chevron_left}
                        </Icon>
                    </button>
                    <button
                        className="carousel-btn right-arrow box-shadow-z1"
                        disabled={!isInfinite && !canScrollRight}
                        onClick={() => onScroll(DIRECTIONS.RIGHT)}
                    >
                        <Icon size={12} className="chevron-icon">
                            {l_chevron_right}
                        </Icon>
                    </button>
                </div>
            </div>
            <div className="slides-list" ref={slidesListRef}>
                <div
                    className={classNames('slides-track', {
                        'dynamic-slides-width': dynamicSlidesWidth,
                    })}
                    onTransitionEnd={() => removeAnimation(slidesTrackRef, setIsAnimating)}
                    style={style}
                    ref={slidesTrackRef}
                >
                    {slides.map((item, index) => (
                        <div key={index} className={classNames('slide', slideClassname)}>
                            <Component componentProps={componentProps} item={item} index={index} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Carousel;
