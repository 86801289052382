import React from 'react';
import { Tag, Section } from '@fiverr-private/fit';
import eventReporter from '../../utils/reporter';
import { EVENT_TYPES } from '../../utils/reporter/constants';

import './index.scss';

interface RelatedLinksProps {
    title: string;
    relatedLinks: RelatedLinksItem[];
    verticalId: number;
    verticalTitle: string;
}

const RelatedLinks = ({ title, relatedLinks, verticalId, verticalTitle }: RelatedLinksProps) => {
    const handleTagClick = (payload) =>
        eventReporter.reportEvent(
            EVENT_TYPES.CLICKED_FLP_LINK,
            eventReporter.mixpanelEventEnrichment(verticalId, verticalTitle, payload)
        );

    return (
        <Section className="vertical-related-links">
            <h4 className="vertical-related-links-title">{title}</h4>
            <ul>
                {relatedLinks?.map(({ url, anchor_text: anchorText, open_in_new_tab: openInNewTab }, index) => {
                    const eventEnrich = {
                        'FLP Location': index + 1,
                        'FLP name': anchorText,
                    };
                    return (
                        <Tag key={index} className="vertical-related-link" size="small" color="light-grey">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a
                                href={url}
                                target={openInNewTab ? '_blank' : ''}
                                onClick={() => handleTagClick(eventEnrich)}
                            >
                                {anchorText}
                            </a>
                        </Tag>
                    );
                })}
            </ul>
        </Section>
    );
};

export default RelatedLinks;
