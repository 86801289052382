import React from 'react';
import { Types } from '@fiverr-private/theme';
import { Typography } from '@fiverr-private/typography';
import { WrapItem } from '@fiverr-private/layout_components';

interface AgenciesBannerTagProps {
    fontColor: Types.SelectorTypographyColorsType;
    backgroundColor: Types.SelectorBackgroundColorsType;
    message: string;
}

export const AgenciesBannerTag: React.FC<AgenciesBannerTagProps> = ({ backgroundColor, fontColor, message }) => (
    <WrapItem width="fit-content" borderRadius="circle" backgroundColor={backgroundColor}>
        <Typography fontSize="b_sm" whiteSpace="nowrap" paddingX="2" paddingY="1" as="p" color={fontColor}>
            {message}
        </Typography>
    </WrapItem>
);
