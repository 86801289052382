import { merge } from 'lodash';
import { BQ_EVENTS } from '../reporter/constants';
import trackingService from './TrackingService';

interface BQEventParams {
    type: BQ_EVENTS;
    group?: string;
    action: {
        type?: string;
    };
    page: {
        element: {
            name: string;
            type?: string;
        };
        component: {
            name: string;
        };
    };
}

const initialiseContext = (context: any) => {
    const { isMobile, userId, pageCtxId, userGuid, organizationId } = context;

    const baseData = {
        context_id: pageCtxId,
        uid: userGuid,
        page: {
            name: 'category',
        },
        platform: isMobile ? 'mobile_web' : 'web',
        user: {
            id: userId,
        },
        organization: {
            id: organizationId,
        },
        domain: {
            name: 'agencies',
        },
        feature: {
            name: 'navigation_bar',
        },
    };

    trackingService.setTrackerReportData(baseData);
};

type SendBQEvent = (
    params: {
        type: BQ_EVENTS;
        actionType?: string;
        group?: string;
        pageName: string;
        pageElementType?: string;
    },
    extension?: { [key: string]: any }
) => void;

const sendBQEvent: SendBQEvent = ({ type, actionType, group, pageName, pageElementType }, extension = {}) => {
    const params: BQEventParams = merge(
        {
            type,
            group,
            action: {
                type: actionType,
            },
            page: {
                element: {
                    name: pageName,
                    type: pageElementType,
                },
                component: {
                    name: pageName,
                },
            },
        },
        extension
    );

    trackingService.sendBQEvent(params);
};

export default {
    initialiseContext,
    sendBQEvent,
};
