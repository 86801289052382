import React from 'react';
import FAQFormatter from './utils/FAQFormatter';
import breadcrumbFormatter from './utils/breadcrumbFormatter';

interface SeoMarkupScriptProps {
    scriptContent: string;
}

const SeoMarkupScript = ({ scriptContent }: SeoMarkupScriptProps) => (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: scriptContent }} />
);

export default SeoMarkupScript;
export { FAQFormatter, breadcrumbFormatter };
