import React from 'react';
import { Section } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { AgenciesBanner } from '../AgenciesBanner/AgenciesBanner';
import { createAgenciesBannerInfo } from '../utils';
import { ROLLOUTS } from '../../../lib/constants/experiments';
import { CATEGORIES_ID_MAPPER } from '../../../lib/constants/verticalsData';
import { ProjectPartnerBanner } from '../ProjectPartnerBanner/ProjectPartnerBanner';

interface BannerSectionProps {
    verticalId: number;
    verticalTitle: string;
    rollouts: { [key: string]: boolean };
    currentBannerIndex: number;
}

const PROJECT_PARTNER_SUPPORTED_CATEGORIES = [
    CATEGORIES_ID_MAPPER['programming-tech'],
    CATEGORIES_ID_MAPPER['online-marketing'],
    CATEGORIES_ID_MAPPER['video-animation'],
    CATEGORIES_ID_MAPPER['writing-translation'],
    CATEGORIES_ID_MAPPER['graphics-design'],
];

const buildActiveBannerComponent = ({ currentBannerIndex, verticalId, verticalTitle, rollouts }) => {
    const agenciesBannerInfo = createAgenciesBannerInfo(verticalId, verticalTitle);
    const { experience } = getContext();
    let showProjectPartnerBanner = false;

    const showAgenciesBanner =
        rollouts[ROLLOUTS.AGENCIES_BANNERS_ROLLOUT] &&
        (verticalId === CATEGORIES_ID_MAPPER['programming-tech'] ||
            verticalId === CATEGORIES_ID_MAPPER['online-marketing'] ||
            verticalId === CATEGORIES_ID_MAPPER['graphics-design']) &&
        agenciesBannerInfo;

    if (
        rollouts[ROLLOUTS.PROJECT_PARTNER_BANNER_ROLLOUT] &&
        PROJECT_PARTNER_SUPPORTED_CATEGORIES.includes(verticalId)
    ) {
        if (experience?.isBusiness) {
            showProjectPartnerBanner = true;
        } else if (verticalId !== CATEGORIES_ID_MAPPER['writing-translation']) {
            showProjectPartnerBanner = rollouts[ROLLOUTS.PROJECT_PARTNER_BANNER_IN_MARKETPLACE_ROLLOUT];
        }
    }

    const bothBannersEnabled = showProjectPartnerBanner && showAgenciesBanner;

    if (bothBannersEnabled && currentBannerIndex) {
        return <AgenciesBanner agenciesBannerInfo={agenciesBannerInfo} />;
    } else if (bothBannersEnabled && !currentBannerIndex) {
        return <ProjectPartnerBanner verticalId={verticalId} verticalTitle={verticalTitle} />;
    } else if (!bothBannersEnabled && showProjectPartnerBanner) {
        return <ProjectPartnerBanner verticalId={verticalId} verticalTitle={verticalTitle} />;
    } else if (!bothBannersEnabled && showAgenciesBanner) {
        return <AgenciesBanner agenciesBannerInfo={agenciesBannerInfo} />;
    }
};

export const BannerSection: React.FC<BannerSectionProps> = ({
    verticalTitle,
    verticalId,
    rollouts,
    currentBannerIndex,
}) => {
    const component = buildActiveBannerComponent({
        currentBannerIndex,
        verticalId,
        verticalTitle,
        rollouts,
    });

    if (!component) {
        return null;
    }

    return (
        <Section paddingBottom="0" paddingTop="14">
            {component}
        </Section>
    );
};
