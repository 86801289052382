import React from 'react';
import i18n from '@fiverr/i18n/singleton';
import { Section } from '@fiverr-private/fit';
import { I18nProvider, hasTranslation } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { getBuyerAssumedLanguage } from '@fiverr-private/localization';
import RelatedGuides from '@fiverr-private/related_guides';
import { HOW_FIVERR_BUSINESS_WORKS, HOW_FIVERR_WORKS } from '../video/constants';
import eventReporter from '../../utils/reporter';
import { SCROLL_TYPE } from '../carousel/constants';
import { BI_CONSTS } from '../../constants';
import { EVENT_TYPES } from '../../utils/reporter/constants';
import MostPopularSlide from '../MostPopularSlide';
import MostPopularHeader from '../MostPopularHeader';
import BucketsContainer from '../BucketsContainer';
import HeroBanner from '../HeroBanner';
import { BannerSection } from '../BannerSection/BannerSection';
import { createSourceInfo } from '../utils';
import RelatedLinks from '../RelatedLinks';
import Carousel from '../carousel';
import Faqs from '../Faqs';

import './index.scss';

interface VerticalPageProps {
    verticalData: IVertical;
    isBusiness: boolean;
    rollouts: any;
    currentBannerIndex: number;
}

const VerticalPage = ({ verticalData, isBusiness, rollouts = {}, currentBannerIndex }: VerticalPageProps) => {
    const { locale, browserLanguage, countryCode } = getContext();
    const assumedLanguage = getBuyerAssumedLanguage({
        locale,
        browserLanguage,
        countryCode,
    });
    const {
        id: verticalId,
        title: verticalTitle,
        mostPopularLinks: { title: mostPopularTitle, items },
    } = verticalData;
    const bannerTitle = i18n.t(`categories.category_${verticalId}.name`, {
        $scope: locale,
    });

    const video = isBusiness ? HOW_FIVERR_BUSINESS_WORKS : HOW_FIVERR_WORKS;

    const sourceInfo = createSourceInfo({
        videoName: `${video.name} - ${assumedLanguage}`,
        mixpanelPageName: BI_CONSTS.mixpanel.pageName,
        bigQueryPageName: BI_CONSTS.bigQuery.pageName,
    });

    const clickOnHowFiverrWorks = () =>
        eventReporter.reportEvent(EVENT_TYPES.CLICKED_HOW_FIVERR_WORKS, {
            categoryId: verticalId,
            categoryName: bannerTitle,
        });

    return (
        <div className="vertical-page">
            <Section equalPadding className="banner-section">
                <HeroBanner
                    verticalData={verticalData}
                    video={video}
                    title={bannerTitle}
                    sourceInfo={sourceInfo}
                    clickOnHowFiverrWorks={clickOnHowFiverrWorks}
                />
            </Section>

            <Section className="carousel-section">
                <Carousel
                    slides={items}
                    dynamicSlidesWidth
                    Header={<MostPopularHeader title={mostPopularTitle} />}
                    scrollType={SCROLL_TYPE.ROW}
                    componentProps={verticalData}
                    carouselClassname="most-popular-carousel"
                    Component={MostPopularSlide}
                />
            </Section>

            <BannerSection
                verticalId={verticalId}
                verticalTitle={verticalTitle}
                rollouts={rollouts}
                currentBannerIndex={currentBannerIndex}
            />

            <Section className="buckets-section" equalPadding>
                <BucketsContainer verticalData={verticalData} />
            </Section>

            <I18nProvider>
                {({ i18n }) => {
                    const {
                        seoData: { relatedLinks = [], relatedGuides = [], seeMore },
                        cachedSlug,
                    } = verticalData;
                    const relatedGuidesSourcePageKey = `verticals_${cachedSlug}`;

                    const faqsTranslationKey = `seo_categories.${verticalData.id}.faqs`;
                    const faqs = hasTranslation(faqsTranslationKey) ? i18n.t(faqsTranslationKey) : [];

                    return (
                        <>
                            {relatedGuides.length > 0 && (
                                <Section className="related-guides-section">
                                    <RelatedGuides
                                        guides={relatedGuides}
                                        seeMoreText={seeMore?.anchorText}
                                        seeMoreLink={seeMore?.url}
                                        title={i18n.t('vertical_page_perseus.seo_sub_category.related_guides.title', {
                                            sub_category_title: verticalData.title,
                                        })}
                                        sourcePageKey={relatedGuidesSourcePageKey}
                                    />
                                </Section>
                            )}
                            {faqs.length > 0 && (
                                <Faqs
                                    title={i18n.t('vertical_page_perseus.seo_sub_category.faqs.title', {
                                        sub_category_title: verticalData.title,
                                    })}
                                    faqs={faqs}
                                    verticalData={verticalData}
                                />
                            )}
                            {relatedLinks.length > 0 && (
                                <RelatedLinks
                                    relatedLinks={relatedLinks}
                                    title={i18n.t('vertical_page_perseus.seo_sub_category.relatedLinks.title', {
                                        sub_category_title: verticalData.title,
                                    })}
                                    verticalId={verticalId}
                                    verticalTitle={verticalTitle}
                                />
                            )}
                        </>
                    );
                }}
            </I18nProvider>
        </div>
    );
};

export default VerticalPage;
